<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- <b-button v-if="this.exibeBtnVoltar" variant="link" size="sm" :to="previousRoute"> <feather-icon icon="ArrowLeftIcon"
        size="20" /> </b-button> -->
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <dark-Toggler class="d-none d-lg-block"/>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-chat-dropdown class="pr-1"/>

      <notification-dropdown class="pr-1"/>
      <setores-dropdown v-if="$can('escolher', 'setores')" class="pr-1"/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SetoresDropdown from '@core/layouts/components/app-navbar/components/SetoresDropdown.vue'
import NotificationChatDropdown from './components/NotificationChatDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Swal from 'sweetalert2'
import * as PusherPushNotifications from '@pusher/push-notifications-web'

export default {
  components: {
    BNavItemDropdown,
    SetoresDropdown,
    BLink,
    NotificationDropdown,
    // Navbar Components
    BNavbarNav,
    NotificationChatDropdown,
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      previousRoute: null,
      exibeBtnVoltar: false,
      deviceInterest: null,
    }
  },
  computed: {
    linkAnterior() {
      return ''
    },
  },
  watch: {
    $route(to, from) {
      this.previousRoute = from.path
      this.exibeBtnVoltar = to.path.split('/').length === 4
    },
  },
  created() {
    this.previousRoute = this.$route.path
    const { host } = window.location

    const usuarioLogado = JSON.parse(localStorage.getItem('userData'))

    if (Notification.permission === 'granted') {

      this.addDeviceInterest(this.deviceInterest)
    }
    if (Notification.permission !== 'granted' && usuarioLogado && !['192', 'localhost'].some(key => host.includes(key))) {
      Swal.fire({
        title: 'Permissão de notificação',
        text: 'Para receber notificações, por favor, permita as notificações do sistema.',
        type: 'info',
        showCancelButton: true,
        confirmButtonText: 'Permitir',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return Notification.requestPermission()
        }
      })
          .then((result) => {
            if (result.value === 'granted') {
              Swal.fire('Permissão concedida!', '', 'success')
              this.addDeviceInterest()
            }
          })
    }

  },
  methods: {
    addDeviceInterest() {
      const { host } = window.location
      let beamsClient
      if (['hgl'].some(key => host.includes(key))) {
        beamsClient = new PusherPushNotifications.Client({
          instanceId: `00f21be0-fd54-4350-8796-40bcc96ad2b8`,
        })
      } else {

        beamsClient = new PusherPushNotifications.Client({
          instanceId: `f956de2e-1d72-4e83-89ff-9a710f3470f1`,
        })
      }

      this.deviceInterest = localStorage.getItem('userData') ? `user.${JSON.parse(localStorage.getItem('userData')).uuid}` : 'user.0'

      beamsClient.start()
          .then(() => beamsClient.getDeviceInterests())
          .then((d) => d.map((i) => {
            if (i !== this.deviceInterest) {
              beamsClient.removeDeviceInterest(i)
            }
          }))
          .then(() => console.log('Device interests have been cleared'))
          .then(() => beamsClient.addDeviceInterest(this.deviceInterest))
          .then(() => console.log('Successfully registered and subscribed!'))
          .then(() => beamsClient.getDeviceInterests())
          .then((interests) => console.log('Current interests:', interests))
          .catch(console.error)
    }
  }
}
</script>
<style>
.swal2-confirm {
  margin-right: 15px;
}
</style>
